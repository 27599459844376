
import { Component, Vue } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import { IndexOfficePayload, Office, OfficeSetViewing } from "@/store/modules/office.store";

@Component({
    components: {
        Spinner: () => import("@/components/general/spinner.vue"),
        PartOfficesShow: () => import("@/views/admin/settings/offices/show.vue"),
        PartOfficesCreate: () => import("@/views/admin/settings/offices/create.vue"),
        LayoutModal: () => import("@/layouts/components/modal.vue"),
    },
})
export default class PageAdminOfficesIndex extends Vue {
    @Getter("office/all") offices!: Office[];
    @Getter("office/viewing") viewingOffice!: Office;

    @Action("office/index") indexOffices!: (payload: IndexOfficePayload) => Promise<Office[]>;
    @Action("office/setViewing") setViewing!: OfficeSetViewing;
    @Action("office/clearViewing") clearViewing!: () => void;

    loaded: boolean = false;
    action: string | null = null;

    created() {
        this.indexOffices({ s: { id: "asc" }, per_page: 99 }).then(() => {
            this.loaded = true;
        });
    }

    showCreate() {
        this.action = "create";
    }

    showEdit(office: Office) {
        this.action = "edit";
        this.setViewing(office);
    }

    showDelete(office: Office) {
        this.action = "delete";
        this.setViewing(office);
    }
}
